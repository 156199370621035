exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partner-forgot-password-js": () => import("./../../../src/pages/partner/forgot-password.js" /* webpackChunkName: "component---src-pages-partner-forgot-password-js" */),
  "component---src-pages-partner-reset-password-js": () => import("./../../../src/pages/partner/reset-password.js" /* webpackChunkName: "component---src-pages-partner-reset-password-js" */),
  "component---src-pages-user-forgot-password-js": () => import("./../../../src/pages/user/forgot-password.js" /* webpackChunkName: "component---src-pages-user-forgot-password-js" */),
  "component---src-pages-user-reset-password-js": () => import("./../../../src/pages/user/reset-password.js" /* webpackChunkName: "component---src-pages-user-reset-password-js" */),
  "component---src-pages-user-verify-js": () => import("./../../../src/pages/user/verify.js" /* webpackChunkName: "component---src-pages-user-verify-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-inner-js": () => import("./../../../src/templates/inner.js" /* webpackChunkName: "component---src-templates-inner-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

